import React, { useEffect } from "react";
import Form from "../contactForm/ContactForm";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    const handleScroll = () => {
      const contact = document.querySelector(".contact");
      if (contact && window.innerWidth > 768) { // Only apply on larger screens
        const scrollY = window.scrollY;
        const offset = 300;
        if (scrollY > contact.offsetTop - window.innerHeight + offset) {
          contact.style.borderRadius = "0";
          contact.style.width = "100%";
        } else {
          contact.style.borderRadius = "50%";
          contact.style.width = "70vw";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="contact">
      <div data-aos="fade-up" data-aos-duration="750" data-aos-delay="100">
        <h2 style={{ textAlign: "center" }} className="contact-title">Kontaktujte mě</h2>
        <Form />
      </div>
    </div>
  );
};

export default Contact;
