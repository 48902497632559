import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css"; // Importujte styly pro Footer

const Footer = () => {
  return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-address">
            <p>Martina Geroltová</p>
            <p>
              <a href="mailto:martina.gerolt@gmail.com">e-mail: martina.gerolt@gmail.com</a>
            </p>
            <p className="footer-links">
              <FontAwesomeIcon icon={faLinkedin} />
              {" "}
              <a
                  className="zemlasoft"
                  href="https://cz.linkedin.com/in/martina-szmek"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </p>
          </div>
          <div className="footer-text">
            <p>
              Investiční, ale také úvěrové produkty zprostředkovávám jako vázaný
              zástupce zapsaný v registru České Národní Banky pod IČO: 02852942 a
              zároveň jsem registrovaným partnerem společnosti Broker Trust, a.s.
              (IČO: 26439719), která vykonává servisní činnost, správu dle GDPR a
              dalších zákonných požadavků.
            </p>
          </div>
          <div className="footer-bottom">
            <p>&copy; 2024 Martina Geroltová. All rights reserved.</p>
            <p>
              Made by Ondřej Kožušník | Made & Outsourced by{" "}
              <a
                  className="zemlasoft"
                  href="https://www.zemlasoft.com"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Zemlasoft s.r.o
              </a>
            </p>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
