import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Container } from "react-bootstrap";
import "../../App.css";

function TimeLine() {
  const iconColorStyle = {
    background: "black",
  };

  return (
    <>
      <div className="timeline-section">
        <h2>
          Mé profesní zkušenosti
        </h2>
      </div>
      <div className="timeline-section">
        <Container className="timeline">
          <VerticalTimeline lineColor="black" animate="false">
            <VerticalTimelineElement
              className="vertical-timeline-element--work "
              iconStyle={{ ...iconColorStyle }}
              contentStyle={{ textAlign: "left"}}
              contentArrowStyle={{ borderRight: "7px solid black" }}
            >
              <h3 className="vertical-timeline-element-title">1993</h3>
              <p>
                Mé profesní začátky kariéry v bankovním sektoru začaly v České spořitelně, a.s. Obsluha klientů, především v oblasti investic a finančního plánování, kde jsem byla ambasadorkou, ale také ve správě osobních financí, prohlubovala můj zájem o tyto služby. Školila jsem své kolegy v oblasti finančního plánování. Dalším krokem v mé kariéře byla příležitost zúčastnit se stáže ve Vídni, na centrále v Erste bank. Byla to skvělá a cenná zkušenost. Banka mi dala velkou příležitost podívat se na obsluhu klientů v zahraničí. Tuto pracovní zkušenost jsem po návratu prezentovala kolegům napříč celou Českou republikou.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work "
              iconStyle={{ ...iconColorStyle }}
              icon
              contentStyle={{ textAlign: "left" }}
              contentArrowStyle={{ borderRight: "7px solid black" }}
            >
              <h3 className="vertical-timeline-element-title">2013</h3>
              <p>
                Zkušenosti v Raiffeisenbank, jako privátní bankéř pro bonitní klientelu, mě v mé kariéře posunuly o další krok. Každá banka má trošku odlišný přístup ke klientům. Zde jsem se v oblasti finančního plánování více zdokonalila, jelikož tato banka má náročnější certifikaci, kterou jsem úspěšně absolvovala.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ ...iconColorStyle }}
              contentStyle={{ textAlign: "left" }}
              contentArrowStyle={{ borderRight: "7px solid black" }}
            >
              <h3 className="vertical-timeline-element-title">2015</h3>
              <p>
                Česká spořitelna mě oslovila další oblastí a možností spolupráce ve firemním bankovnictví. Ráda se rozvíjím, a tak jsem tuto zkušenost ve svém rozvoji uvítala.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ ...iconColorStyle }}
              contentStyle={{ textAlign: "left" }}
              contentArrowStyle={{ borderRight: "7px solid black" }}
            >
              <h3 className="vertical-timeline-element-title">2020</h3>
              <p>
                Komerční banka, pozice investičního specialisty, byla dalším krokem a posunem v mých znalostech. Měla jsem na starosti 3 pobočky banky a její poradce. Školení poradců a vzdělávání v oblasti investic byly mou pracovní náplní.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ ...iconColorStyle }}
              contentStyle={{ textAlign: "left" }}
              contentArrowStyle={{ borderRight: "7px solid black" }}
            >
              <h3 className="vertical-timeline-element-title">2020</h3>
              <p>
                Tento rok byl zlomový, neboť jsem vystoupila z komfortní zóny zaměstnanců a navázala jsem spolupráci se společností Minefi, s.r.o., a společností BrokerTrust, a.s. Významně se mi rozšířil okruh produktových řešení, která jsem mohla svým klientům předkládat. Nezávislost na konkrétní bance a komunikace s bývalým kolegou z banky mi přišly jako nejlepší řešení pro mé klienty.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Container>
      </div>
    </>
  );
}

export default TimeLine;
