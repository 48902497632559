import Navbar from "./components/navbar/Navbar.js";
import Contact from "./components/contact/Contact.js";
import Reference from "./components/reference/Reference.js";
import Timeline from "./components/timeline/Timeline.js";
import Accordion from "./components/accordion/Accordion.js";
import Footer from "./components/footer/Footer.js";

import heroimage from "./assets/Illustration.png";
import aboutimage from "./assets/Illustration3.png";
import servicesimage from "./assets/2Illustration.png";
import geroltova from "./assets/geroltova.png";
import useParallaxEffect from "./components/parallax";
import "./App.css";
import AOS from "aos";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useParallaxEffect();

  return (
    <div className="App">
      <Navbar />
      <div className="background-image euro position1"></div>
      <div className="background-image dollar position2"></div>
      <div className="background-image euro position3"></div>
      <div className="background-image dollar position4"></div>
      <div className="background-image euro position5"></div>
      <div className="background-image dollar position6"></div>
      <div className="container-hero">
        <div className="hero">
          <div
            className="word"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-delay="100"
          >
            <p>Finanční</p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-delay="250"
            className="word"
          >
            <p>Svoboda</p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-delay="400"
            className="word"
          >
            <p>Začíná</p>
          </div>
        </div>
        <div className="martina-container">
          <div
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-delay="500"
            className="img-wrapper"
          >
            <img src={heroimage} alt="" />
          </div>
        </div>
      </div>
      <div id="about-section" className="about-me">
        <div className="about-left">
          <img
            src={geroltova}
            alt="Geroltova foto"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-delay="100"
          />
        </div>
        <div
          className="about-right"
          data-aos="fade-up"
          data-aos-duration="750"
          data-aos-delay="100"
        >
          <h2>Čím se zabývám?</h2>
          <p>
            Pomáhám vašemu majetku pracovat lépe, komplexně a nezávisle. Úspěšně spravuji finance movitých rodin, významných firem, obcím, neziskovým organizacím a nadacím. Moje priorita je individuální přístup ke každému klientovi. Návrhy řešení jsou časem upravovány, aby co nejlépe odpovídaly vašim aktuálním potřebám.
          </p>
          <div className="certificates">
            <ul>
              <li>Certifikát odborné způsobilosti k poskytování investičních služeb ČNB</li>
              <li>Certifikát odborné způsobilosti pro poskytování spotřebitelských a hypotečních úvěrů ČNB</li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="timeline-section"
        data-aos="fade-up"
        data-aos-duration="750"
        data-aos-delay="100"
      >
        <Timeline />
      </div>
      <div
        className="myServices"
        id="myServices"
        data-aos="fade-up"
      >
        <h2 className="accordion-section-header">S čím vším vám mohu pomoct</h2>
        <img src={servicesimage} alt="" />
        <Accordion />
      </div>
      <div className="services">
        <div className="services-container">
          <div
            className="services-content"
            data-aos="fade-up"
          >
            <h2>Jsem váš finanční kompas na cestě k úspěchu</h2>
            <img src={aboutimage} alt="" />
          </div>
          <div
            className="cards"
            data-aos="fade-up"
          >
            <div className="card">
              <h3>Bankovní zkušenosti</h3>
              <p>
                Největší přidanou hodnotou je má dlouholetá praxe v privátním bankovnictví velkých bank. Díky mnohaletým zkušenostem pečuji o své klienty v té nejvyšší možné kvalitě. Cílím na kvalitu a dlouhodobou spolupráci.
              </p>
            </div>
            <div className="card">
              <h3>Poplatková struktura</h3>
              <p>
                Jsem dostatečně velká na to, abych vám zajistila ty nejlepší možné podmínky. U mě nenajdete nesmyslné vstupní částky za nepotřebné produkty ani poplatky za péči.
              </p>
            </div>
            <div className="card">
              <h3>Komplexní služby</h3>
              <p>
                Součástí mého týmu jsou také specialisté, zkušení kolegové z bank, kteří poradí nejen s hypotékou, ale také s pojištěním auta, nemovitosti a dalších nezbytných náležitostí.
              </p>
            </div>
            <div className="card">
              <h3>Právní poradenství</h3>
              <p>
                Pomůžu vám v případě sporů s pojišťovnami. Například v situaci, kdy vám pojišťovna odmítá uznat pojistné plnění z uzavřené smlouvy.
              </p>
            </div>
            <div className="card">
              <h3>Transparentnost</h3>
              <p>
                Pracuji výhradně pod dohledem České národní banky. Nejsem součástí žádné MLM finanční struktury a vždy vybírám to nejvhodnější řešení individuálně pro vás.
              </p>
            </div>
            <div className="card">
              <h3>Komunita</h3>
              <p>
                Jsem součástí komunity poradců v rámci mezinárodní asociace FEIFA, spolupracujeme s Expat centrem v Ostravě a také s MSIC Ostrava
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="reference-section">
        <Reference />
      </div>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
