import { useEffect } from 'react';

const useParallaxEffect = () => {
  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const hero = document.querySelector('.hero');
          const image = document.querySelector('.img-wrapper img');
          if (hero && image) {
            const scrollY = window.scrollY;
            let transformValue, opacityValue;

            if (window.innerWidth > 900) {
              // Na větších zařízeních aplikujte plný paralax efekt
              transformValue = `translateY(${scrollY * 0.5}px) scale(${1 - scrollY * 0.0002})`;
              opacityValue = `${1 - scrollY * 0.0012}`;
            } else {
              // Na menších zařízeních aplikujte mírnější efekt nebo žádný efekt
              transformValue = `translateY(${scrollY * 0.11}px)`;
              opacityValue = `${1 - scrollY * 0.0012}`;
            }

            hero.style.transform = transformValue;
            hero.style.opacity = opacityValue;
            image.style.transform = transformValue;
            image.style.opacity = opacityValue;
          }
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};

export default useParallaxEffect;
