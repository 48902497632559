import React from 'react';

const Review = ({ title, content, author }) => {
  return (
    <div className="keen-slider__slide flex h-full items-center justify-center bg-gray-50 p-6 rounded-2xl">
      <blockquote className="flex flex-col justify-between h-full p-6 bg-white rounded-xl shadow sm:p-8 lg:p-12">
        <div>
          <div className="mt-4">
            <p className="text-2xl font-bold text-gray-700 sm:text-3xl">{title}</p>
            <p className="mt-4 leading-relaxed text-gray-700">{content}</p>
          </div>
        </div>
        <footer className="mt-4 text-sm font-medium text-gray-700 sm:mt-6">&mdash; {author}</footer>
      </blockquote>
    </div>
  );
};

export default Review;
