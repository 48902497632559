import React, { useState } from 'react';
import "./Navbar.css";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const scrollToAbout = (e) => {
    e.preventDefault();
    const aboutSection = document.getElementById("about-section");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };

  const scrollToServices = (e) => {
    e.preventDefault();
    const servicesSection = document.getElementById("myServices");
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };

  const scrollToReference = (e) => {
    e.preventDefault();
    const referenceSection = document.getElementById("reference-section");
    if (referenceSection) {
      referenceSection.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };

  const scrollToBottom = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <a href="#" className="navbar-logo">
          Martina Geroltová
        </a>
      </div>

      <div className="hamburger" onClick={toggleNavbar}>
        &#9776;
      </div>

      <div className={`navbar-links ${isOpen ? 'nav-active' : ''}`}>
        <div className="navbar-center">
          <a href="#" onClick={scrollToAbout} className="navbar-link">
            O mně
          </a>
          <a href="#" onClick={scrollToServices} className="navbar-link">
            Služby
          </a>
          <a href="#" onClick={scrollToReference} className="navbar-link">
            Reference
          </a>
        </div>

        <div className="navbar-right">
          <a href="#" onClick={scrollToBottom} className="navbar-link">
            Pojďme na to
          </a>
        </div>
      </div>

    </nav>
  );
}
