import React, { useEffect } from "react";
import KeenSlider from "keen-slider";
import "keen-slider/keen-slider.min.css";
import Review from "./reviewCard/Review";
import "./Reference.css";

const reviews = [
  {
    title: "Aktuální investiční portfolio",
    content:
      "Finanční jistoty, aktuální investiční portfolio a výhodné podmínky jsou nezbytnou součástí denního života pro nás klienty napříč profesemi i generacemi. Proto vyhledávám profesionály v tomto oboru, kam paní Martina Geroltová díky své letité zkušenosti bezesporu patří. Příjemné jednání a osobní přístup je přidanou hodnotou.",
    author: "MgA. Markéta Fassati, sopranistka a podnikatelka",
  },
  {
    title: "Zhodnocení Vašich financí",
    content:
      "Paní Geroltová je schopna poskytnout naprosto jiné informace a doporučení pro zhodnocení vašich financí než jiní finanční poradci. Výsledky jsou o stovky procent lepší, než jsem byla zvyklá u jiných poradců, a přístup je milý a přátelský. Spolupráce s paní Geroltovou mě baví a přináší zisky pro mě i moji společnost.",
    author: "Ing. Bednářová Dagmar, jednatel společnosti, DB Inkaso s.r.o.",
  },
  {
    title: "Klidný přístup, důvěra, úsměv na obličeji",
    content:
      "Znám Martinu už několik let. Jednou mě přesvědčila, abych investoval peníze do fondů, které mi nabídla. Byl jsem skeptický, ale ukázalo se, že měla pravdu, a od té doby investuji s Martinou pravidelně. Klidný přístup, důvěra a úsměv na obličeji jsou to, co mám rád.",
    author: "Marek Ciennik Szymon Business & Technical Consulting",
  },
  {
    title: "Poradí, zařídí, nikdy není problém",
    content:
      "Touto cestou bych chtěla Martině Geroltové udělit 5 hvězdiček z 5, a to za profesionalitu, spolehlivost, serióznost, empatii a pozitivní myšlení. Dokáže srozumitelně vysvětlit, jak nakládat s financemi a investicemi. Poradí, zařídí, nikdy není problém se sejít a domluvit termín, proto ji ráda doporučuji svým známým. V podstatě se stala její rodina součástí té mé, z profesní roviny to zcela přirozeně přešlo do přátelství, kterého si vážím.",
    author: "Lenka Fridvaldská, finanční ředitel, PICCOLLO SPORT s.r.o.",
  },
  {
    title: "Naprosto bezkonkurenční",
    content:
      "Martiny profesionalita a odborné znalosti jsou naprosto bezkonkurenční. Schopnost orientovat se ve finančních situacích je obdivuhodná. Pro mě je báječným člověkem, její přístup dává do každého jednání něco navíc. Můj celkový dojem ze spolupráce s Martinou je vždy mimořádně pozitivní a doporučuji ji všem, kdo hledají odborníka v oblasti financí.",
    author: "Ing. & Bc. Gabriela Šimanová MBA, jednatel společností IRU s.r.o. a Envirostav s.r.o. C.A.T. nf",
  },
  {
    title: "Profesionální a přínosné",
    content:
      "Služby Martiny Geroltové byly naprosto profesionální a přínosné. Martina je velmi znalá, ochotná a poskytuje osobní přístup. Díky jejím radám a podpoře jsem dosáhl významných zisků. Vřele doporučuji služby, které Martina jako profesionál v bankovnictví nabízí.",
    author: "Mgr.Tomáš Kocourek, advokát Advokátní kancelář Chytil & Mann",
  },
  {
    title: "Mým individuálním potřebám",
    content:
      "Martina mi poskytuje vynikající služby. Díky jejímu odbornému přístupu a důkladným znalostem finančních trhů je její poradenství vždy přesné a přizpůsobené mým individuálním potřebám, což mi dává pocit jistoty a klidu. Díky její práci moje investice stabilně rostou a já se cítím finančně zajištěná.",
    author: "Fridvaldská Dita, projektový manažer, Státní tiskárna cenin",
  },
  {
    title: "Ukázala mi nový směr",
    content:
      "Paní Martina je velice profesionální finanční poradkyně, své práci opravdu rozumí. Ukázala mi nový směr, jak zhodnotit své peníze. Plně ji důvěřuji, protože již několikrát dokázala, že je velmi zkušená. S paní Martinou nemám o své investice obavu. Skvělá komunikace, spolehlivost a lidskost.",
    author: "Dobrevová Zdeňka, sociální obor",
  },
];

const Reference = () => {
  useEffect(() => {
    const keenSlider = new KeenSlider(
      "#keen-slider",
      {
        loop: true,
        slides: {
          origin: "center",
          perView: 1.25,
          spacing: 16,
        },
        breakpoints: {


          "(min-width: 1024px)": {
            slides: {
              origin: "auto",
              perView: 1.2,
              spacing: 32,
            },
          },
          "(min-width: 1280px)": {
            slides: {
              origin: "auto",
              perView: 1.5,
              spacing: 32,
            },
          },

        },
      },
      []
    );

    const keenSliderPrevious = document.getElementById("keen-slider-previous");
    const keenSliderNext = document.getElementById("keen-slider-next");
    const keenSliderPreviousDesktop = document.getElementById(
      "keen-slider-previous-desktop"
    );
    const keenSliderNextDesktop = document.getElementById(
      "keen-slider-next-desktop"
    );

    keenSliderPrevious.addEventListener("click", () => keenSlider.prev());
    keenSliderNext.addEventListener("click", () => keenSlider.next());
    keenSliderPreviousDesktop.addEventListener("click", () =>
      keenSlider.prev()
    );
    keenSliderNextDesktop.addEventListener("click", () => keenSlider.next());

    return () => {
      keenSlider.destroy();
    };
  }, []);

  return (
    <section className="ref-sec bg-gray-50 h-sevenvh">
      <div className="reference-slider mx-[5%] pt-12 sm:px-6 lg:py-16 lg:pe-0 lg:ps-8 xl:py-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:items-center lg:gap-12">
          <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <h2>Výsledky mé práce...</h2>

            <p className="mt-4 text-gray-700 text-left">
              Každému klientovi dávám maximum a snažím se, aby byl spokojený.
              Zde je několik recenzí od mých klientů.
            </p>

            <div className="hidden lg:mt-8 lg:flex lg:gap-4 ">
              <button
                aria-label="Previous slide"
                id="keen-slider-previous-desktop"
                className="rounded-full border border-black p-3 text-black transition hover:bg-black-600 hover:text-black"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 rtl:rotate-180"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </button>

              <button
                aria-label="Next slide"
                id="keen-slider-next-desktop"
                className="rounded-full border border-black p-3 text-black transition hover:bg-black-600 hover:text-black"
              >
                <svg
                  className="size-5 rtl:rotate-180"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="-mx-6 lg:col-span-2 lg:mx-0">
            <div id="keen-slider" className="keen-slider">
              {reviews.map((review, index) => (
                <Review
                  key={index}
                  title={review.title}
                  content={review.content}
                  author={review.author}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="button-group mt-8 flex justify-center gap-4 lg:hidden">
          <button
            aria-label="Previous slide"
            id="keen-slider-previous"
            className="rounded-full border border-black p-3 text-black transition hover:bg-black-600 hover:text-black"
          >
            <svg
              className="size-5 -rotate-180 transform"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>

          <button
            aria-label="Next slide"
            id="keen-slider-next"
            className="rounded-full border border-black p-3 text-black transition hover:bg-black-600 hover:text-black"
          >
            <svg
              className="h-5 w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Reference;
