import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    telephone: "",
    email: "",
    message: "",
  });

  const [formValidity, setFormValidity] = useState({
    name: false,
    telephone: false,
    email: false,
    message: false,
  });

  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");

  const form = useRef();

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let isValid = value.trim() !== "";

    if (name === "telephone") {
      isValid = validatePhoneNumber(value);
    }

    if (name === "email") {
      isValid = validateEmail(value);
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setFormValidity({
      ...formValidity,
      [name]: isValid,
    });
  };

  const isFormValid = Object.values(formValidity).every((value) => value);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid) {
      setMessage("Please fill out all fields correctly.");
      setMessageColor("red");
      return;
    }

    emailjs
      .sendForm(
        "service_ghtyiw7",
        "template_3i0nkxs",
        form.current,
        "Jd-116zmARA3GO7sz"
      )
      .then(
        () => {
          setFormData({
            name: "",
            telephone: "",
            email: "",
            message: "",
          });

          setFormValidity({
            name: false,
            telephone: false,
            email: false,
            message: false,
          });
          setMessage("Děkuji brzy se Vám ozvu.");
          setMessageColor("white");
          setTimeout(() => {
            setMessage("");
          }, 5500);
        },
        (error) => {
          console.log("Failed to send the form:", error.text);
          setMessage("Formulář se nepodařilo odeslat.");
          setMessageColor("red");
        }
      );
  };

  return (
    <footer className="contact-form-footer">
      <form ref={form} className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Vaše jméno"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            id="telephone"
            name="telephone"
            placeholder="Vaše telefonní číslo"
            value={formData.telephone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Váš email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            id="message"
            name="message"
            placeholder="Vaše zpráva"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          disabled={!isFormValid}
          style={{ background: isFormValid ? "#007bff" : "grey" }}
        >
          Odeslat
        </button>
        <div>
          <h3 style={{ color: messageColor }}>{message}</h3>
        </div>
      </form>
    </footer>
  );
};

export default ContactForm;