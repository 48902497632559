import React, { useState } from 'react';
import './Accordion.css';

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <button className="accordion-header" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`accordion-icon ${isOpen ? 'rotate' : ''}`}>+</span>
      </button>
      <div className={`accordion-content ${isOpen ? 'open' : ''}`}>
        {content}
      </div>
    </div>
  );
};

const Accordion = () => {
  const items = [
    { title: 'Investice', content: 'Vždy s vámi proberu a vysvětlím investiční možnosti a příležitosti uložení vašich financí. Ráda vám zároveň zkontroluji vaše současné produkty a společně se domluvíme na jejich nastavení.' },
    { title: 'Hypotéky', content: 'Spolupracuji se všemi bankami v České republice. Díky svým mnohaletým zkušenostem z bank vám ráda pomůžu s vyjednáváním těch nejlepších podmínek pro vás.' },
    { title: 'Pojištění', content: ( <> Pojištění domova, automobilu, zdraví i pojištění na cesty vám pomůžu správně nastavit: <a href='https://www.pojistenisnadno.cz' target="_blank" rel="noopener noreferrer" style={{color: 'blue'}}>www.pojistenisnadno.cz</a> </> )},
  ];

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default Accordion;
